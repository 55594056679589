.App {
  text-align: left;

}

.App-logo {
  height: 40px;
  pointer-events: none;
}

.App-main {
  background-color: #faffbb;
  padding: 1rem;
}

.m1 {
  margin: 0.5rem;
}

/*
各ページ上部に表示する、ページ名の上のマージン
page_titleに明記すると、コンテンツ中央にページ名を明記した場合、その真上に余計な空白が出来る為、page_titleには入れられない。
*/
.topspace_height {
  margin-top: 75px;
}

/* 各ページ上部に表示する、ページ名 */
.page_title {
  margin-bottom: 0px;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 1.0rem;

  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #ccc, #eee);
}

.search_space {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-image: linear-gradient(to right, #eee, #fff);
}

/* メインコンテンツを表示するスペース */
.main_space {
  padding: 0px;
  margin: 20px 10px 20px 10px;
  background-color: #fff;

  /* height: calc(100vmax - 150px); */
}

/* メインコンテンツのタイトル表示するスペース */
.content_title {
  font-size: 1.0rem;
  font-weight: normal;
  padding: 2rem 1rem 2rem 1rem;
  border-top: 1px solid #FF6928;
  border-right: 1px solid #FF6928;
  border-bottom: 1px solid #FF6928;
  border-left: 10px solid #FF6928;
}

/*
  background-color: #fef4e5;

*/

.content_subtitle {
  font-size: 1.0rem;
  font-weight: normal;
  padding: 2rem 1rem 2rem 1rem;
  margin: 2rem 0rem 2rem 0rem;
  background-color: #e7f6fd;
  border-top: 1px solid #28A3FF;
  border-right: 1px solid #28A3FF;
  border-bottom: 1px solid #28A3FF;
  border-left: 10px solid #28A3FF;
  box-shadow: 0px 0px 13px 0px #ddd;
  color: black;
}

.content_prevnextcalendarlink {
  font-size: 0.8rem;
  font-weight: normal;
  padding: 0.1rem 1rem 0.1rem 1rem;
  margin: 1rem 0rem 1rem 0rem;
  background-color: #E5F4FF;
  border-top: 1px solid #B2DEFF;
  border-right: 1px solid #B2DEFF;
  border-bottom: 1px solid #B2DEFF;
  border-left: 1px solid #B2DEFF;
  box-shadow: 0px 0px 13px 0px #ddd;
  color: black;
}


.content_hr {
  background-color: #B2DEFF;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.hr_tooltop {
  margin: 0.5rem 0rem;
  border-top: 2px dotted #808080;
}

/* メインコンテンツを表示するスペース */
.content_subbox {
  line-height: 1.4rem;
  padding: 0px 5px 0px 5px;
}

/* メインコンテンツを表示するスペース */
.content_subbox_txt {
  line-height: 1.8rem;
  padding: 0px 5px 0px 0px;
}

/*

##  css構造 ##
<main_space>
  <content_title>
  </content_title>
  <content_subtitle>
  </content_subtitle>
  <content_subbox>
    メインコンテンツ
  </content_subbox>
</main_space>

*/

.calendar_info_box {
  display: flex;
  flex-direction: row-reverse;
}

.calendar_info_row_left {
  font-size: 0.9rem;
  min-width: 6rem;
  max-width: 6rem;
  text-align: right;
  background-color: #fff;
}

.calendar_info_row_right {
  flex-grow: 1;
  background-color: #fff;
}

/* span内では、改行しない */
span {
  display: inline-block;
}

.table_enclosure {
  max-width: 100%;
  margin: 0 auto;
  background-color: white;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.swipe_table {
  border-collapse: collapse;
  width: 100%;
}

.swipe_table_cell {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-top: solid 5px #fff;
  border-bottom: solid 5px #fff;
  border-right: solid 5px #fff;
  white-space: nowrap;
  text-align: start;
  font-weight: normal;
  letter-spacing: 0.1rem;
  color: black;
}

.swipe_table_th_fixing {
  z-index: 9;

  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  left: 0;
}

.calendar_bgcolor {
  background-color: #f8f8f8
}

ul {
  padding-left: 1rem;
}

li {
  list-style: none;
  line-height: 1.8rem;
}

.inside-list-style-decimal {
  margin-left: 1rem;
  list-style-position: outside;
  list-style-type: decimal;
}

.inside-list-style-none {
  margin-left: 1.5rem;
  list-style-type: none;
}

.inside-list-style {
  list-style-position: inside;
  list-style-type: disc;
}

.inside-list-style-sub {
  list-style-position: inside;
  list-style-type: circle;
}

.inside-list-style-sub-sub {
  list-style-position: inside;
  list-style-type: disc;
  color: #aaa;

}

a {
  color: #3277d5;
}

.aspect-angle-icon {
  color: white;
  background-color: black;
  font-weight: bold;
  border: 1px solid black;
  vertical-align: middle;
  margin: 0px 0px 5px 10px;
}